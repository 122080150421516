import React from 'react';
import {
  Card, CardImg, CardText,
  CardTitle, Row, Col
} from 'reactstrap';
import contents from './contents/about';
import './css/About.css';
import useBlurImage from './hooks/useBlurImage';
import lowImg from "./images/headshot-small.jpg";
import highImg from "./images/headshot-large.jpg"

function About(){

  const [src, { blur }] = useBlurImage(
    lowImg,
    highImg,
  );

  return (
    <div className="about-container mt-5">
      <Row>
        <Col sm="6"> 
          <Card body className="about-card border-0">
            <CardImg className="about-image mx-auto" 
                     src={src} 
                     style={{
                      objectFit: "contain",
                      filter: blur
                        ? "blur(20px)"
                        : "none",
                      transition: blur
                        ? "none"
                        : "filter 0.3s ease-out"
                     }}
                     alt="moi" 
            />
          </Card>
        </Col>
        <Col sm="6">
          <Card body className="border-0">
            <CardText style={{textAlign: "left", marginBottom: 1 + 'px'}}>My name is</CardText>
            <CardTitle className="about-title">Majick</CardTitle>
            <div className="mt-3" style={{textAlign: "left"}}>{contents.aboutGraf}</div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default About;