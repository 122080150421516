import React from 'react';

const projectData = [
  {
    name: "jobly",
    description: "A job application site for searching and applying to jobs in various companies.",
    demoLink: "",
    repoLink: `https://github.com/majxxi/jobly`,
    libraries: <p>Built with: <strong>React</strong>, <strong>Node Express</strong>, and <strong>PostgreSQL</strong>.</p>
  },
  {
    name: "warbler",
    description: "A social media app similar to Twitter.",
    demoLink: `https://r16-warbler.herokuapp.com`,
    repoLink: `https://github.com/majxxi/warbler`,
    libraries: <p>Built with: <strong>Python</strong>, <strong>Flask</strong>, and <strong>PostgreSQL</strong>.</p>
  },
  {
    name: "majxxi",
    description: "My personal site.",
    demoLink: `http://majxxi.net`,
    repoLink: `https://github.com/majxxi/majickero`,
    libraries: <p> Built with <strong>React</strong> and customized <strong>CSS</strong>.</p>
  }
  // {
  //   name: "microblog",
  //   description: "An anonymous microblogging app with editing, commenting, and voting features.",
  //   demoLink: "",
  //   repoLink: `https://github.com/majxxi/microblog`,
  //   libraries: <p>Built with: <strong>React</strong>, <strong>Redux</strong>, and <strong>PostgreSQL</strong>.</p>
  // }
]



export default projectData;