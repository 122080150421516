import React from "react";

const contents = {
  aboutGraf: <div><p>... and I'm a writer, a photographer, and a <strong>software engineer</strong> who 
    aspires to make a social impact by creating exquisite and efficient
    interfaces and experiences. I left Santa Monica and moved to the Bay Area 
    to study Rhetoric at University of California, Berkeley and I've been based 
    in here since. After working as a Web Content Writer and Content Review 
    Specialist for years, I realized that I wanted to be more hands-on with the 
    product inception, development, and design. So, I--compelled by my appetite 
    for learning--decided to take <strong>Rithm School's full stack web development program</strong> in 
    San Francisco where I sharpened my knowledge on JavaScript, Python, and databases. 
    After getting my hands dirty through working on a couple of projects, I am confident
    that my drive for delivering quality and my diligence for detail make me an skillful engineer. 
    Combined with my previous professional experience and my unbridled artistic 
    interest, <strong>I can be a significant member of your team and bring a refreshing 
    perspective that's guided by creativity, adaptability, and a regard for the 
    greater good.</strong> </p> <p>Aside from coding, I also consume a lot of media. I enjoy reading (more so out of obligation due
    to the fact that I'm backlogged), taking photos with a film camera and wait for about a week to have
    them processed, going through the Criterion Channel, and rewatching Mad Men and Gilmore Girls.</p></div>,
}

export default contents;
