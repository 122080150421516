import React from 'react';
import {
  Card, CardTitle, Row, Col
} from 'reactstrap';
import skills from './contents/skills';
import './css/Skills.css';

function Skills(){
  

  return (
    <div className="skills-container">
      <Row>
        {Object.entries(skills).map(pair => (
          <Col md="4" key={pair[0]}>
            <Card className="skill-card border-0">
              <CardTitle className="skill-card-title">{pair[0]}</CardTitle>
              {pair[1].map(skill => (
                <ul className="mb-1" key={skill}>{skill}</ul>
              ))}
            </Card>
          </Col>
        ))}

      </Row>
    </div>
  )

}

export default Skills;