import React from 'react';
import './css/App.css';
import Menu from './Menu';
import Routes from './Routes';


function App() {
  return (
    <div className="App">
      <Menu />
      <Routes />
    </div>
  );
}

export default App;
