import React, { useState } from "react";
import { Nav, Navbar, Collapse, NavItem, NavbarToggler } from "reactstrap";
import { NavLink } from 'react-router-dom';
import blackLogo from './images/logo-double.png';
import purpleLogo from './images/logo-double-purple.png';
import './css/Menu.css';

function Menu(){

  const [isOpen, setIsOpen] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);

  const toggle = () => setIsOpen(!isOpen);


  return (
    <div className="menu-container">
      <Navbar className="navbar" light={true} expand="md">
        <NavLink to="/" className="navbar-brand">
          <img className="logo" expand="sm" src={mouseHover ? purpleLogo : blackLogo} alt="logo" onMouseOver={() => setMouseHover(true)} onMouseLeave={() => setMouseHover(false)}/>
        </NavLink>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className="ml-3">
              <NavLink to="/about">About</NavLink>
            </NavItem>
            <NavItem className="ml-3">
              <NavLink to="/projects">Projects</NavLink>
            </NavItem>
            <NavItem className="ml-3">
              <NavLink to="/skills">Skills</NavLink>
            </NavItem>
            <NavItem className="ml-3">
              <NavLink to="/contact">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Menu;