import React from 'react';
import './css/Contact.css';
import contents from './contents/contact';

function Contact(){
  return (
    <div>
      <div className="contacts-message">{contents.message}</div>
      <div className="contacts col-4 mt-5">
        <a href={contents.email}>EMAIL</a>
          <div></div>
        <a href={contents.github} target="blank">GITHUB</a>
          <div></div>
        <a href={contents.linkedin} target="blank">LINKEDIN</a>
      </div>
    </div>
  )
}

export default Contact;