import React from "react";
import { Row, Card, CardTitle } from 'reactstrap';
import projectData from './contents/projects';
import './css/Projects.css';

function Projects(){
  return (
    <div className="projects-container">
      {projectData.map(project => (
        <Row key={project.name}>
          <Card className="project">
            <CardTitle className="project-title">{project.name}</CardTitle>
            {project.description} {project.libraries}
            {project.demoLink ? <a href={project.demoLink} target="blank" className="btn">Demo</a> : null}
            <a href={project.repoLink} target="blank" className="btn">GitHub</a>
          </Card>
        </Row>
      ))}
    </div>
  )
}

export default Projects;