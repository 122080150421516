import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { CardImg } from 'reactstrap';
import About from "./About";
import Projects from "./Projects";
import Skills from './Skills';
import Contact from "./Contact";
import lowImg from "./images/los_angeles_small.jpg";
import highImg from './images/los_angeles.jpg';

import useBlurImage from './hooks/useBlurImage';
import './css/App.css';


function Routes() {

  const [src, { blur }] = useBlurImage(
    lowImg,
    highImg,
  );


  return (
    <Switch>
      <Route exact path="/">
        <CardImg id="main-photo" object="true" src={src} alt="at the 405." 
                 style={{
                  filter: blur
                    ? "blur(20px)"
                    : "none",
                  transition: blur
                    ? "none"
                    : "filter 0.3s ease-out"
                  }}
        />
      </Route>

      <Route exact path="/about">
        <About />
      </Route>

      <Route exact path="/projects">
        <Projects />
      </Route>

      <Route exact path="/skills">
        <Skills />
      </Route>
      
      <Route exact path="/contact">
        <Contact />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
}

export default Routes;